<template>
  <el-form ref="articleForm" :model="formData" label-width="200px">
    <el-date-picker
        v-model="formData.date"
        placeholder="年度"
        type="month"
        format="yyyy年MM月"
        value-format="yyyy-MM"
        @change="handleQuery">
    </el-date-picker>
    <el-button v-if="userPermissions.indexOf('kol-analysis-batch-setting') !== -1" style="margin: 0 10px"
               type="primary" @click="syncBtn('btn')">
      初始化
    </el-button>

    <el-button v-if="userPermissions.indexOf('kol-analysis-setting-delete') !== -1" style="margin: 0 10px"
               type="primary" @click="delBtn">
      清空{{ this.formData.date }}月数据
    </el-button>
    <br/>
    <br/>
    <el-form-item v-if="userPermissions.indexOf('kol-analysis-batch-setting') !== -1" label="批量设置红人固定费用(元)"
                  prop="divide_rate">
      <el-input oninput="value=value.replace(/[^0-9.]/g,'')" style="width: 200px;margin-right: 10px"
                clearable v-model="formData.fixed_cost"></el-input>
      <el-link type="primary" @click="setBatch('fixed')"><i class="el-icon-s-order"></i> 批量设置
      </el-link>
    </el-form-item>
    <el-form-item v-if="userPermissions.indexOf('kol-analysis-batch-setting') !== -1" label="批量设置红人分成比例(%)">
      <el-row>
        <el-input oninput="value=value.replace(/[^0-9.]/g,'')" style="width: 200px;margin-right: 10px"
                  clearable v-model="formData.divide_rate"></el-input>
        <el-link type="primary" @click="setBatch('divide')"><i class="el-icon-s-order"></i> 批量设置
        </el-link>
      </el-row>
    </el-form-item>
    <el-divider/>
    <el-row>
      <el-select @change="handleQuery" filterable clearable placeholder="选择红人" v-model="formData.artist_id">
        <el-option :key="index" :label="item.nickname" :value="item.artist_id" v-for="(item,index) in kolList">
          <span style="float: left"> {{ item.nickname }} </span>
        </el-option>
      </el-select>
      <el-button style="margin-left: 10px" @click='handleQuery' type='primary' icon='el-icon-search'>查询
      </el-button>
      <el-button v-if="userPermissions.indexOf('kol-analysis-setting-sync') !== -1"
                 @click='sysLast' type='primary'>同步上月
      </el-button>
    </el-row>
    <br/>
    <br/>
    <div class="default-table" v-if="userPermissions.indexOf('kol-analysis-setting-list') !== -1">
      <el-table :data="dataList" ref="multipleTable" row-key="id"
                highlight-current-row
                v-loading="isLoading" border>
        <el-table-column align="center" label="序号" type="index" width="60"></el-table-column>
        <el-table-column align="center" label="红人昵称" min-width="200"
                         show-overflow-tooltip prop="nickname"></el-table-column>
        <el-table-column align="center" label="月份"
                         show-overflow-tooltip prop="month">
          <template slot-scope="{row}">
            {{ row.month.slice(0, 7) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="分成比例"
                         show-overflow-tooltip prop="divide_rate">
          <template slot-scope="{row}">
            <span class='ratio'>{{ row.divide_rate }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="固定费用"
                         show-overflow-tooltip prop="fixed_cost">
          <template slot-scope="{row}">
            <span class='money'>{{ moneyFormat(row.fixed_cost) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间"  max-width="100"
                         show-overflow-tooltip prop="created_at"></el-table-column>
        <el-table-column align="center" label="修改时间" max-width="100"
                         show-overflow-tooltip prop="updated_at"></el-table-column>
        <el-table-column align="center" label="操作"
                         show-overflow-tooltip prop="">
          <template slot-scope="{row}">
            <el-popover
                v-if="userPermissions.indexOf('kol-analysis-setting-save') !== -1"
                title="修改数据"
                placement="right"
                v-model="row.visible"
                trigger="click">
              <el-form label-width="80px">
                <el-form-item label="固定费用">
                  <el-input oninput="value=value.replace(/[^0-9.]/g,'')" style="width: 100px;margin-right: 10px"
                            v-model="editFrom.fixed_cost"></el-input>
                </el-form-item>
                <el-form-item label="分成比例">
                  <el-input oninput="value=value.replace(/[^0-9.]/g,'')" style="width: 100px;margin-right: 10px"
                            v-model="editFrom.divide_rate"></el-input>
                </el-form-item>
              </el-form>
              <div style="float: right">
                <el-button size="mini" type="text" @click="row.visible = false">取消</el-button>
                <el-button type="primary" size="mini" @click="editBtn(row.id)">确定</el-button>
              </div>
              <el-link  type="primary" @click="clickEdit(row)" slot="reference"><i class="el-icon-edit"></i>
                修改
              </el-link>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>

      <el-row>
        <el-col :span='24' style='text-align: right'>
          <Pagination :limit.sync='pagingData.page_size'
                      :page.sync='pagingData.current_page'
                      :total='pagingData.total'
                      @pagination='getList'/>
        </el-col>
      </el-row>
    </div>
    <br/>
  </el-form>

</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout.vue'
import ApeUploader from '@/components/ApeUploader.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SettingArtistModel',
  components: {
    PageHeaderLayout,
    ApeUploader
  },
  data() {
    return {
      hearTitle: '基本设置',
      loadingStaus: false,
      formData: { date: '', fixed_cost: '', divide_rate: '', artist_id: '' },
      kolList: [],
      dataList: [],
      pagingData: {
        page_size: 10,
        current_page: 1,
        total: 0
      },
      isLoading: false,
      lastYear: '',
      visible: false,
      editFrom: {
        divide_rate: '',
        fixed_cost: ''
      },
      current: {
        month: '',
        year: ''
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  methods: {
    syncBtn(type) {
      let msg = `检测到未生成${this.formData.date}月的数据，是否初始化数据（当月在约红人）`
      if (type === 'btn') {
        msg = `是否初始化${this.formData.date}月的数据（当月在约红人）`
      }
      this.$confirm(
          msg,
          '提示',
          {
            confirmButtonText: '初始化',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
        let id = await this.$api.kolModelInit({ date: this.formData.date + '-01' })
        if (id) {
          this.$message({
            message: '同步成功', type: 'success'
          })
          await this.getList()
        }
      })

    },
    delBtn() {
      this.$confirm(
          `是否需要清空${this.formData.date}月现有数据`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
        let id = await this.$api.kolModelEmpty({ month: this.formData.date + '-01' })
        if (id) {
          this.$message({
            message: '清空成功', type: 'success'
          })
          await this.getList()

        }
      })
    },
    clickEdit(row) {
      this.editFrom.divide_rate = row.divide_rate
      this.editFrom.fixed_cost = row.fixed_cost
    },
    moneyFormat(value) {
      return this.$utils.numberFormat(value, 2, '.', ',', 'round')
    },
    setBatch(type) {
      switch (type) {
        case 'fixed':
          if (this.formData.fixed_cost != '') {
            this.$confirm(
                `将批量设置${this.formData.date}月所有红人固定费用(${this.formData.fixed_cost})，确定修改吗`,
                '提示',
                {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
              this.getBatch({ date: this.formData.date + '-01', fixed_cost: Number(this.formData.fixed_cost) })
              setTimeout(() => {
                this.getList()
              }, 500)

            }).catch(() => {
              this.$notify.info('已取消')
            })
          } else
            this.$notify.error('请填写固定费用！')
          break
        case 'divide':
          if (this.formData.divide_rate != '') {
            if (this.formData.divide_rate > 100) {
              this.$notify.error('红人分成比例不能大于100%！')
              return false
            }
            this.$confirm(
                `将批量设置${this.formData.date}月所有红人分成比例(${this.formData.divide_rate})，确定修改吗`,
                '提示',
                {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
              this.getBatch({ date: this.formData.date + '-01', divide_rate: Number(this.formData.divide_rate) })
              setTimeout(() => {
                this.getList()
              }, 500)

            }).catch(() => {
              this.$notify.info('已取消')
            })
            // this.getBatch({ date: this.formData.date + '-01', divide_rate: Number(this.formData.divide_rate) })
          } else this.$notify.error('请填写红人分成比例！')
          break
        default:
          if (this.formData.divide_rate != '' && this.formData.fixed_cost != '') {
            this.getBatch({
              date: this.formData.date + '-01',
              divide_rate: Number(this.formData.divide_rate),
              fixed_cost: Number(this.formData.fixed_cost)
            })
          }

      }

    },
    async getBatch(subData) {
      let data = await this.$api.kolModelGetInitbatch(subData)
      if (data)
        this.$notify.success('设置成功！')

    },
    async editBtn(id) {
      if (this.editFrom.divide_rate > 100) {
        this.$notify.error('红人分成比例不能大于100%！')
        return false
      }
      let subData = {
        divide_rate: Number(this.editFrom.divide_rate),
        fixed_cost: Number(this.editFrom.fixed_cost),
        id: id
      }
      let data = await this.$api.kolModelSaveSetting(subData)
      if (data) {
        this.$notify.success('保存成功！')
        await this.getList()
      }
    },
    // 确认保存按钮
    async saveConfirm() {
      // 调用组件的数据验证方法
      this.$refs['articleForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
      return true
    },
    // 站点相关信息的保存处理
    async formSubmit() {
      this.loadingStaus = true
      let id = await this.$api.saveSettingSiteInfo(this.formData)
      if (id) {
        this.$notify.success('保存成功！')
      }
      this.$nextTick(() => {
        this.loadingStaus = false
      })
    },
    handleQuery() {
      this.pagingData.current_page = 1
      let year = this.formData.date.slice(0, 4)
      let month = this.formData.date.slice(5, 7)
      if (Number(year) === this.current.year && this.current.month < Number(month)) {
        this.$message.error('超过当前日期，暂未有当月数据')
        this.formData.date = `${this.current.year}-${this.current.month}-01`
      } else
        this.getList()
    },
    async getList() {
      this.isLoading = true
      this.dataList = []
      //
      let selectInfo = {
        page_size: this.pagingData.page_size || 10,
        current_page: this.pagingData.current_page || 1,
        month: this.formData.date + '-01'
      }
      this.formData.artist_id !== '' ? selectInfo.artist_id = this.formData.artist_id : ''
      let { list, page_info } = await this.$api.kolModelGetInitList(selectInfo)
      // kol-analysis-setting-init
      if (page_info.total === 0 && !selectInfo.artist_id && this.userPermissions.indexOf('kol-analysis-setting-init') !== -1) {
        this.syncBtn()
      } else {
        this.dataList = list
        this.pagingData = page_info
      }
      this.isLoading = false

    },

    async sysLast() {
      let year = this.formData.date.slice(0, 4)
      let month = Number(this.formData.date.slice(5, 7))
      let sysDate = { year: year, month: this.compareNumber(month - 1) }
      if (month != 1) {
        sysDate.month = this.compareNumber(month - 1)
      } else {
        sysDate.year = year - 1,
            sysDate.month = 12
      }
      this.$confirm(
          `确定将同步${sysDate.year}-${sysDate.month}月的数据同步到${year}-${this.compareNumber(month)}月吗？`,
          '提示',
          {
            confirmButtonText: '同步',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
        this.isLoading = false
        let id = await this.$api.kolModelGetInitsyncLast({ date: `${year}-${month}-01` })

        if (id) {
          await this.getList()
        } else {
          this.$message.error('未知错误，请联系管理员')
        }
      })

    },

    getDate() {
      let date = new Date() // 获取时间
      let year = date.getFullYear() // 获取年
      let month = this.compareNumber(date.getMonth() + 1) // 获取月
      this.current = { year: year, month: month }
      if (Number(month) !== 1) {
        this.formData.date = `${year}-${month - 1}`
      } else this.formData.date = `${year - 1}-${month}`
      this.getList()
    },
    compareNumber(num) {
      return num < 10 ? '0' + num : num
    },
    async getKol() {
      this.kolList = await this.$api.kolModelartistlist({ month: this.formData.date + '-01' })
    }

  },
  mounted() {
    this.loadingStaus = true
    this.getDate()
    this.getKol()
  }

}
</script>
<style scoped>
.form {
  width: 990px;
  margin: 30px auto;

}

.right-button {
  display: block;
  text-align: center;
  margin: 0 auto;
  padding: 30px;
}
</style>

